import { Dispatch } from 'redux';
import {
  loadAllVehicles,
  loadServicePage,
  loadLandingPage,
  loadNewsItems,
  loadContactUsPage,
  loadAboutUsPage,
  loadOfferPage,
  loadFooterData,
  loadDisclaimerData,
  loadTermsData,
  loadPrivacyData,
  loadAllMotorCycles,
  loadAllPowerProducts,
  loadAllMarines,
  loadAboutServicePage,
  loadMainMenu,
} from './cms-service';
import { CMSStateModel, ILocalization } from './cms-view-models';
export * from './cms-view-models';

// initial state
export const initialCMSState: CMSStateModel = {
  slugDetails: undefined,
  vehicleData: {
    isLoading: false,
    isLoaded: false,
    allVehicles: [],
  },
  menuData: {
    isLoading: false,
    isLoaded: false,
  },
  powerProductData: {
    isLoading: false,
    isLoaded: false,
    items: [],
  },
  marineData: {
    isLoading: false,
    isLoaded: false,
    items: [],
  },
  motorcycleData: {
    isLoading: false,
    isLoaded: false,
    allMotorCycles: [],
  },
  serviceData: {
    isLoaded: false,
    isLoading: false,
  },
  landingData: {
    isLoaded: false,
    isLoading: false,
  },
  newsData: {
    isLoading: false,
    isLoaded: false,
    data: [],
  },
  contactData: {
    isLoaded: false,
    isLoading: false,
  },
  aboutData: {
    isLoaded: false,
    isLoading: false,
  },
  aboutServiceData: {
    isLoaded: false,
    isLoading: false,
  },
  offerData: {
    isLoaded: false,
    isLoading: false,
  },
  footerData: {
    isLoaded: false,
    isLoading: false,
  },
  disclaimer: {
    isLoaded: false,
    isLoading: false,
  },
  terms: {
    isLoaded: false,
    isLoading: false,
  },
  privacy: {
    isLoaded: false,
    isLoading: false,
  },
};

// reducer
export const cmsReducer = (
  state: CMSStateModel = initialCMSState,
  action: any
): CMSStateModel => {
  switch (action.type) {
    case cmsActionTypes.SetSlugDetails:
      return {
        ...state,
        slugDetails: action.data,
      };
    case cmsActionTypes.LoadAllVehicles:
      return {
        ...state,
        vehicleData: {
          ...state.vehicleData,
          allVehicles: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetVehicleLoader:
      return {
        ...state,
        vehicleData: {
          ...state.vehicleData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadAllPowerProducts:
      return {
        ...state,
        powerProductData: {
          ...state.powerProductData,
          items: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetPowerProductsLoader:
      return {
        ...state,
        powerProductData: {
          ...state.powerProductData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadAllMotorcycles:
      return {
        ...state,
        motorcycleData: {
          ...state.motorcycleData,
          allMotorCycles: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetMarineLoader:
      return {
        ...state,
        marineData: {
          ...state.marineData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadAllMarines:
      return {
        ...state,
        marineData: {
          ...state.marineData,
          items: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetMotorCycleLoader:
      return {
        ...state,
        motorcycleData: {
          ...state.motorcycleData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadServiceData:
      return {
        ...state,
        serviceData: {
          ...state.serviceData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetServiceLoader:
      return {
        ...state,
        serviceData: {
          ...state.serviceData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadLandingData:
      return {
        ...state,
        landingData: {
          ...state.landingData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetLandingLoader:
      return {
        ...state,
        landingData: {
          ...state.landingData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadMenuData:
      return {
        ...state,
        menuData: {
          ...state.menuData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetMenuLoader:
      return {
        ...state,
        menuData: {
          ...state.menuData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadNewsData:
      return {
        ...state,
        newsData: {
          ...state.newsData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetNewsLoader:
      return {
        ...state,
        newsData: {
          ...state.newsData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadContactData:
      return {
        ...state,
        contactData: {
          ...state.contactData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetContactLoader:
      return {
        ...state,
        contactData: {
          ...state.contactData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadAboutData:
      return {
        ...state,
        aboutData: {
          ...state.aboutData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetAboutLoader:
      return {
        ...state,
        aboutData: {
          ...state.aboutData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadAboutServiceData:
      return {
        ...state,
        aboutServiceData: {
          ...state.aboutServiceData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetAboutServiceLoader:
      return {
        ...state,
        aboutServiceData: {
          ...state.aboutServiceData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadOfferData:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetOfferLoader:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadFooterData:
      return {
        ...state,
        footerData: {
          ...state.footerData,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetFooterLoader:
      return {
        ...state,
        footerData: {
          ...state.footerData,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadDisclaimerData:
      return {
        ...state,
        disclaimer: {
          ...state.disclaimer,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetDisclaimerLoader:
      return {
        ...state,
        disclaimer: {
          ...state.disclaimer,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadTermsData:
      return {
        ...state,
        terms: {
          ...state.terms,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetTermsLoader:
      return {
        ...state,
        terms: {
          ...state.terms,
          isLoading: action.data,
        },
      };
    case cmsActionTypes.LoadPrivacyData:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          data: action.data,
          isLoaded: true,
        },
      };
    case cmsActionTypes.SetPrivacyLoader:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          isLoading: action.data,
        },
      };
    default:
      return state;
  }
};

export const cmsActionTypes = {
  SetSlugDetails: 'SetSlugDetails',
  LoadAllVehicles: 'LoadAllVehicles',
  SetVehicleLoader: 'SetVehicleLoader',
  LoadAllMotorcycles: 'LoadAllMotorcycles',
  SetMotorCycleLoader: 'SetMotorCycleLoader',
  LoadAllPowerProducts: 'LoadAllPowerProducts',
  SetPowerProductsLoader: 'SetPowerProductsLoader',
  LoadAllMarines: 'LoadAllMarines',
  SetMarineLoader: 'SetMarineLoader',
  LoadServiceData: 'LoadServiceData',
  SetServiceLoader: 'SetServiceLoader',
  LoadLandingData: 'LoadLandingData',
  SetLandingLoader: 'SetLandingLoader',
  LoadMenuData: 'LoadMenuData',
  SetMenuLoader: 'SetMenuLoader',
  LoadNewsData: 'LoadNewsData',
  SetNewsLoader: 'SetNewsLoader',
  LoadContactData: 'LoadContactData',
  SetContactLoader: 'SetContactLoader',
  LoadAboutData: 'LoadAboutData',
  SetAboutLoader: 'SetAboutLoader',
  LoadAboutServiceData: 'LoadAboutServiceData',
  SetAboutServiceLoader: 'SetAboutServiceLoader',
  LoadOfferData: 'LoadOfferData',
  SetOfferLoader: 'SetOfferLoader',
  LoadFooterData: 'LoadFooterData',
  SetFooterLoader: 'SetFooterLoader',
  LoadDisclaimerData: 'LoadDisclaimerData',
  SetDisclaimerLoader: 'SetDisclaimerLoader',
  LoadTermsData: 'LoadTermsData',
  SetTermsLoader: 'SetTermsLoader',
  LoadPrivacyData: 'LoadPrivacyData',
  SetPrivacyLoader: 'SetPrivacyLoader',
};

// action creators & async actions
export const cmsActions = {
  setSlugDetails:
    (locale?: string, slug?: string, localizations?: ILocalization[]) =>
    async (dispatch: Dispatch) => {
      if (!locale || !slug || !localizations) {
        dispatch({
          type: cmsActionTypes.SetSlugDetails,
          data: undefined,
        });
      } else {
        let englishSlug;
        let arabicSlug;
        if (locale === 'en') {
          englishSlug = slug;
          arabicSlug = localizations?.find((p) => p.locale === 'ar')?.slug;
        } else {
          arabicSlug = slug;
          englishSlug = localizations?.find((p) => p.locale === 'en')?.slug;
        }
        dispatch({
          type: cmsActionTypes.SetSlugDetails,
          data: { englishSlug, arabicSlug },
        });
      }
    },
  getAllVehicles:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetVehicleLoader, data: true });
      try {
        const response = await loadAllVehicles(language);
        await dispatch({
          type: cmsActionTypes.LoadAllVehicles,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetVehicleLoader, data: false });
      }
    },
  getAllMotorCycles:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetMotorCycleLoader, data: true });
      try {
        const response = await loadAllMotorCycles(language);

        await dispatch({
          type: cmsActionTypes.LoadAllMotorcycles,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetMotorCycleLoader, data: false });
      }
    },
  getAllPowerProducts:
    (currentLanguage: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetPowerProductsLoader, data: true });
      try {
        const response = await loadAllPowerProducts(currentLanguage);

        await dispatch({
          type: cmsActionTypes.LoadAllPowerProducts,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetPowerProductsLoader, data: false });
      }
    },
  getAllMarines:
    (currentLanguage: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetMarineLoader, data: true });
      try {
        const response = await loadAllMarines(currentLanguage);

        await dispatch({
          type: cmsActionTypes.LoadAllMarines,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetMarineLoader, data: false });
      }
    },

  getServicePageData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetVehicleLoader, data: true });
      try {
        const response = await loadServicePage(language);

        await dispatch({
          type: cmsActionTypes.LoadServiceData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetVehicleLoader, data: false });
      }
    },
  getLandingData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetLandingLoader, data: true });
      try {
        const response = await loadLandingPage(language);

        await dispatch({
          type: cmsActionTypes.LoadLandingData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetLandingLoader, data: false });
      }
    },
  getMainMenuData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetMenuLoader, data: true });
      try {
        const response = await loadMainMenu(language);

        await dispatch({
          type: cmsActionTypes.LoadMenuData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetMenuLoader, data: false });
      }
    },
  getNewsData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetNewsLoader, data: true });
      try {
        const response = await loadNewsItems(language);

        await dispatch({
          type: cmsActionTypes.LoadNewsData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetNewsLoader, data: false });
      }
    },
  getContactData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetContactLoader, data: true });
      try {
        const response = await loadContactUsPage(language);

        await dispatch({
          type: cmsActionTypes.LoadContactData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetContactLoader, data: false });
      }
    },
  getAboutData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetAboutLoader, data: true });
      try {
        const response = await loadAboutUsPage(language);

        await dispatch({
          type: cmsActionTypes.LoadAboutData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetAboutLoader, data: false });
      }
    },
  getAboutServiceData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetAboutServiceLoader, data: true });
      try {
        const response = await loadAboutServicePage(language);

        await dispatch({
          type: cmsActionTypes.LoadAboutServiceData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetAboutServiceLoader, data: false });
      }
    },
  getOfferData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetOfferLoader, data: true });
      try {
        const response = await loadOfferPage(language);

        await dispatch({
          type: cmsActionTypes.LoadOfferData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetOfferLoader, data: false });
      }
    },
  getFooterData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetFooterLoader, data: true });
      try {
        const response = await loadFooterData(language);

        await dispatch({
          type: cmsActionTypes.LoadFooterData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetFooterLoader, data: false });
      }
    },
  getDisclaimerData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetDisclaimerLoader, data: true });
      try {
        const response = await loadDisclaimerData(language);

        await dispatch({
          type: cmsActionTypes.LoadDisclaimerData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetDisclaimerLoader, data: false });
      }
    },
  getTermsData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetTermsLoader, data: true });
      try {
        const response = await loadTermsData(language);

        await dispatch({
          type: cmsActionTypes.LoadTermsData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetTermsLoader, data: false });
      }
    },
  getPrivacyData:
    (language: string, onSuccess?: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: cmsActionTypes.SetPrivacyLoader, data: true });
      try {
        const response = await loadPrivacyData(language);

        await dispatch({
          type: cmsActionTypes.LoadPrivacyData,
          data: response,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: cmsActionTypes.SetPrivacyLoader, data: false });
      }
    },
};
