import { bannerItem, image, statusCondition } from './cmsUtils';

export const getAboutUSScreenQuery = (language: string) => {
  return `query Aboutuses {
      aboutuses(locale: "${language}",${statusCondition}) {
        banner ${bannerItem}
        aboutUsItem {
          image ${image}
          content
        }
      metaDetails ${metaDetails}
    }
  }`;
};

export const getAboutServiceScreenQuery = (language: string) => {
  return `query AboutServices {
    aboutServices(locale: "${language}",${statusCondition}) {
        banner ${bannerItem}
        title
        description
      }
  }`;
};

export const getContactUsScreenQuery = (language: string) => {
  return `query Contactuses {
    contactuses(locale: "${language}",${statusCondition}) {
      title
      banner ${bannerItem}
      autoMobiles ${conactViewItem}
      motorCycles ${conactViewItem}
      powerProduct ${conactViewItem}
      marine ${conactViewItem}
      metaDetails ${metaDetails}
    }
}`;
};

const contact = `{
  title
  address
  phone
  email
  link
  fax
  postalCode
  category
  timings
  city
}`;

const conactViewItem = `{
  showRooms ${contact}
  serviceCenters ${contact}
}`;

const metaDetails = `{
  title
  description
  keyword
  ogTitle
}`;
