export enum RouteKeys {
  Home = '/',
  TrimLEvel = '/trim-level/:modelCode/',
  MotorCycleTrimLEvel = '/motorcycle/trim-level/:modelCode/',
  EditionVariant = '/edition/:year/:modelCode/:trim/',
  BuildVehicle = '/build-vehicle/:id',
  MotorCycleBuildVehicle = '/motorcycle/build-vehicle/:id',
  PayNow = '/pay-now/',
  PaymentCallback = '/payment-callback/',
  Auth = '/auth/',
  ForgotPassword = '/auth/forgot-password',
  MyAccount = '/my-account/',
  NotFound = '/page-not-found/',
  Build = '/build',
  MotorCycleBuild = '/motorcycle/build',
  AutoMobiles = '/automobiles/:model',
  MotorCycle = '/motorcycle/:model',
  MarineDetail = '/marine/:type/:model/:slug/',
  MotorCyclesListing = '/motorcycles/:model',
  MarinesListing = '/marines/:model',
  MarinesListingLanding = '/marines/',
  PowerProductDetail = '/powerproduct/:type/:model/:slug/',
  PowerProductListing = '/powerproducts/:model',
  PowerProductListingLanding = '/powerproducts/',
  BlogListing = '/bloglisting/',
  BlogDetails = '/blogdetails/',
  AboutUs = '/aboutus/',
  AboutService = '/about-service/',
  Testimonial = '/testimonial/',
  Contact = '/contact/',
  PowerProductOfferListing = '/powerproducts-offer-listing/',
  MotorcycleOfferListing = '/motorcycle-offer-listing/',
  AutomobileOfferListing = '/automobile-offer-listing/',
  MarineOfferListing = '/marine-offer-listing/',
  ServiceOfferListing = '/service-offer-listing/',
  Finance = '/finance',
  VehicleWarranty = '/vehicle-warranty',
  Products = '/products/:id/:model?',
  PaymentLink = '/paymentrequest',
  PaymentLinkSuccess = '/paymentrequest-success',
  NewsDetail = '/news-detail/:slug',
  CarService = '/car-service',
  Owners = '/owners',
  BodyPaint = '/body-paint',
  Parts = '/parts',
  Fleet = '/fleet',
  PrivacyPolicy = '/privacy-policy',
  Terms = '/terms',
  Disclaimer = '/disclaimer',
  QuickForms = '/quick-forms/:formType',
  Offers = '/offers/:slug',
  ServiceOffers = '/service-offers/:slug',
  OfferThankYou = '/offer-thank-you',
  MotorCycleLanding = '/motor-cycle-landing',
  Dealers = '/dealers',
  AutomobileMenuLanding = '/automobile-landing/',
  MotorcycleMenuLanding = '/motorcycle-landing',
  PowerProductMenuLanding = '/powerproduct-landing',
  MarineLanding = '/marine-landing',
  QuickAccessThankYou = '/:quickAccess/thank-you',
}

export enum QuickAccessForms {
  serviceAppointment = 'service-appointment',
  testDrive = 'test-drive',
  downloadBrochure = 'download-brochure',
  callback = 'callback',
  requestQuote = 'request-quote',
  showroomVisit = 'showroom-visit',
  enquiry = 'enquiry',
  carService = 'car-service',
  parts = 'parts',
  fleet = 'fleet',
  contact = 'contact',
}
