// const menuQuery = `where:{showInMainMenu:true}`;
const isDraftMode = process.env.REACT_APP_IS_DRAFT_MODE;
enum status {
  draft = 'draft',
  published = 'published',
}
const statusConditionWithMenu = `
where:{showInMainMenu:true,status:"${
  isDraftMode === 'Yes' ? status.draft : status.published
}"}
`;
const statusConditionWithType = `
status:"${isDraftMode === 'Yes' ? status.draft : status.published}"
`;
export const statusCondition = `
where:{status:"${isDraftMode === 'Yes' ? status.draft : status.published}"}
`;

const metaDetails = `{
  title
  description
  keyword
  ogTitle
}`;

export const getAllVehiclesQuery = (currentLanguage: string) => {
  return `query Automobiles  {
    automobiles (locale: "${currentLanguage}",${statusConditionWithMenu}){
      type
      slug
      ecomModelCode
      title
      price
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
    }
  }
    `;
};

export const getVehicleDetailsQuery = (locale: string, slug: string) => {
  return `query Automobiles {
    automobiles(locale: "${locale}",where:{slug:"${slug}"}) {
      metaDetails ${metaDetails}
      type
      displayImage${image}
      id
      slug
      title
      subTitle
      ecomProductId
      ecomModelCode
      price
      offerPrice
      buildLink
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
      banner ${bannerItem}
      specs {
        label,
        value
     }
      design{
          id,
          title
          items${imageWithDescription}

      }
      detailItems{
        title
        subtitle
        items${imageWithDescription}
      }
    
      gallery${image},
      colorVariants{
        title
        colorIcon${image}
        image${image}
      }
      interiorColorVariants{
        title
        colorIcon${image}
        image${image}
      }
      showInMainMenu
      homePageSliderSpec{
        spec1
        spec2
      }

      trims{
        title
        subTitle
        price
        infoText
        spec{
          spefcItem
        }
        image${image}
      }
      convenience{
        title
        description
        image${image}
      }
    }
  }
    `;
};
export const getAllMotorcycleQuery = (currentLanguage: string) => {
  return `query Motorcycles {
    motorcycles (locale: "${currentLanguage}",${statusConditionWithMenu}) {
      type
      slug
      title
      price
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
    }
   
  }
    `;
};

export const getMotorcycleDetailQuery = (locale: string, slug: string) => {
  return `query Motorcycles {
    motorcycles(locale: "${locale}",where:{slug:"${slug}"}) {
      ecomProductId
      metaDetails ${metaDetails}
      type
      showInMainMenu
      homePageSliderSpec{
        spec1
        spec2
      }

      displayImage${image}
      id
      slug
      title
      subTitle
      price
      offerPrice
      buildLink
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
      banner ${bannerItem}
      specs {
        label,
        value
     }
     
      detailItems{
        title
        subtitle
        items${imageWithDescription}
      }
    
      gallery${image},
      colorVariants{
        title
        colorIcon${image}
        image${image}
      }
      trims{
        title
        subTitle
        price
        infoText
        spec{
          spefcItem
        }
        image${image}
      }
      sliderImage${image}
    }
   
  }
    `;
};

export const getAllPowerProductQuery = (currentLanguage: string) => {
  return `query PowerProducts {
    powerProducts (locale: "${currentLanguage}",${statusConditionWithMenu}) {
      type
      slug
      title
      price
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
      metaDetails ${metaDetails}
    }
  }
    `;
};

export const getPowerProductDetailQuery = (slug: string) => {
  return `query PowerProducts {
    powerProducts(locale: "all",where:{slug:"${slug}"}) {
      metaDetails ${metaDetails}
      type
      id
      slug
      showInMainMenu
      title
      subTitle
      description
      displayImage${image}
      price
      offerPrice
      buildLink
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
      banner ${bannerItem}
      specs {
        label,
        value
     }
    detailItems{
        title
        subtitle
        items${imageWithDescription}
     }
     mainSpecifications{
      col1
      col2
      col3
      col4
     }
      
    }
   
  }
    `;
};

export const getAllMarineQuery = (currentLanguage: string) => {
  return `query Marines {
    marines (locale: "${currentLanguage}",${statusConditionWithMenu}) {
      type
      slug
      title
      price
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
    }
  }
    `;
};

export const getMarinesForLandingQuery = (
  currentLanguage: string,
  type?: string
) => {
  if (type) {
    return `query Marines {
      marines (locale: "${currentLanguage}",where:{type:"${type}",${statusConditionWithType}}) {
        type
        slug
        title
        subTitle
        ecomProductId
        price
        megaMenuThumb {
          url
        }
        displayImage {
          url
        }
        locale
        localizations ${localizations}
      }
    }
      `;
  } else {
    return `query Marines {
      marines (locale: "${currentLanguage}",where:{${statusConditionWithType}}) {
        type
        slug
        title
        subTitle
        ecomProductId
        price
        megaMenuThumb {
          url
        }
        displayImage {
          url
        }
        locale
        localizations ${localizations}
      }
    }
      `;
  }
};
export const getPowerProductsForLandingQuery = (
  currentLanguage: string,
  type?: string
) => {
  if (type) {
    return `query PowerProducts {
    powerProducts (locale: "${currentLanguage}",where:{type:"${type}",${statusConditionWithType}}) {
      type
      category
      slug
      title
      subTitle
      ecomProductId
      price
      megaMenuThumb {
        url
      }
      displayImage {
        url
      }
      locale
      localizations ${localizations}
    }
  }
    `;
  } else {
    return `query PowerProducts {
      powerProducts (locale: "${currentLanguage}",where:{${statusConditionWithType}}) {
        type
        slug
        title
        category
        subTitle
        ecomProductId
        price
        megaMenuThumb {
          url
        }
        displayImage {
          url
        }
        locale
        localizations ${localizations}
      }
    }
      `;
  }
};

export const getMotorcyclesForLandingQuery = (
  currentLanguage: string,
  type: string
) => {
  return `query Motorcycles {
    motorcycles (locale: "${currentLanguage}",where:{type:"${type}",${statusConditionWithType}}) {
      type
      category
      slug
      title
      subTitle
      price
      offerPrice
      megaMenuThumb {
        url
      }
      displayImage {
        url
      }
      locale
      localizations ${localizations}
    }
  }
    `;
};
export const getMarineDetailQuery = (slug: string) => {
  return `query Marines {
    marines(locale: "all",where:{slug:"${slug}"}) {
      metaDetails ${metaDetails}
      type
      showInMainMenu
      id
      slug
      title
      subTitle
      description
      price
      offerPrice
      buildLink
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
      banner ${bannerItem}
      specs {
        label,
        value
     }
    detailItems{
        title
        subtitle
        items${imageWithDescription}
     }
     accordian{
       title
       headerWithSpec{
         label
         value
       }

     }
      
    }
   
  }
    `;
};

export const getServiceScreenQuery = (language: string) => {
  return `query Service {
    service(locale: "${language}") {
        banner ${bannerItem}
        serviceItems {
          title
          description
          image {
            url
          }
        }
    }
}`;
};

// export const getContactUsScreenQuery = (language: string) => {
//   return `query ContactUs {
//     contactUs(locale: "${language}") {
//       title
//       banner ${bannerItem}
//       contacts ${contact}
//       departmentContacts {
//         title
//         openingDetails
//         phone
//         email
//       }

//     }
// }`;
// };

// export const getAboutUSScreenQuery = (language: string) => {
//   return `query AboutUs {
//     aboutUs(locale: "${language}") {
//       banner ${bannerItem}
//         welcome{
//           title
//           description
//         }
//         items {
//           title
//           description
//           image {
//             url
//           }
//         }
//     }
// }`;
// };

export const getVehicleWarrantyScreenQuery = (language: string) => {
  return `query VehicleWarranties {
    vehicleWarranties(locale: "${language}",${statusCondition}) {
        banner ${bannerItem}
        items {
          image ${image}
          content
        }
      metaDetails ${metaDetails}
    }
  }`;
};

export const getOfferScreenQuery = (language: string) => {
  return `query Offer {
    offer(locale: "${language}") {
      banner ${bannerItem}
       
        landingScreen {
          midSlider ${midSlider}
        }
        offerItem {
          title
          details
        }
    }
}`;
};

export const getFooterQuery = (language: string) => {
  return `query Footers {
    footers(locale: "${language}",${statusCondition}) {
        title
        copyRight
        menuItems {
          title
           items {
            label
            relativeUrl
            absoluteUrl
            action
          }
        }
        socialMedia{
          icon
          url
          title
        }
        serviceLinks {
          title
           items {
            label
            relativeUrl
            absoluteUrl
            action
          }
          isDisable
        }
        connectWithUs {
          title
           items {
            label
            relativeUrl
            absoluteUrl
            action
          }
          isDisable
        }
        extraLinks {
          title
           items {
            label
            relativeUrl
            absoluteUrl
            action
          }
          isDisable
        }
    }
}`;
};

export const getDisclaimerQuery = (language: string) => {
  return `query Disclaimers {
    disclaimers(locale: "${language}",${statusCondition}) {
        content
      metaDetails ${metaDetails}
    }
}`;
};

export const getTermsQuery = (language: string) => {
  return `query Terms {
    terms(locale: "${language}",${statusCondition}) {
        content
        metaDetails ${metaDetails}
      }
}`;
};

export const getPrivacyQuery = (language: string) => {
  return `query Privacies {
    privacies(locale: "${language}",${statusCondition}) {
        content
        metaDetails ${metaDetails}
      }
}`;
};

export const getNewsQuery = (language: string, limit?: number) => {
  return `query NewsItems {
    newsItems(locale: "${language}",${statusCondition},sort:"date:desc"${
    limit != null ? ',limit :' + limit : ''
  }) {
      title
      shortDescription
      date
      slug

      image {
          url
        }
      locale
      localizations {
        locale
        id
        slug
      }
    }
}`;
};

export const getNewsDetailsQuery = (language: string, slug: string) => {
  return `query NewsItems {
    newsItems(locale: "${language}",where:{slug:"${slug}"}) {
      metaDetails ${metaDetails}
      title
      shortDescription
      description
      date
      slug

      image {
          url
        }
      locale
      localizations {
        locale
        id
        slug
      }
    }
}`;
};

export const homeSliderItem = `{
  title
  slug
  price
  image {
    url
  }
  spec1
  spec2
 
}`;

export const homeSliderMarineItem = `{
  title
  type
 
  image {
    url
  }
  
 
}`;

export const getMainMenuQuery = (language: string) => {
  return `query MainMenus {
    mainMenus(locale: "${language}",${statusCondition})  {
      automobileMenu {
        type
        title
        price
        image ${image}
        slug
      }
      motorcycles ${categoryMenu}
      power_products ${powerProductMenu}
      marines ${powerProductMenu}
    }
  }
`;
};
export const image = `{
  name
  url
}`;
const categoryMenu = `{
  type
  category
  active
  title
  price
  image ${image}
  slug

}`;
const powerProductMenu = `{
  type
  title
  price
  slug
  image ${image}
  productId

}`;

export const getLandingScreenQuery = (language: string) => {
  return `query MainLandingScreens {
    mainLandingScreens(locale: "${language}",${statusCondition})  {
      metaDetails ${metaDetails}
      topSlider ${bannerItem}
      automobileSlider ${homeSliderItem}
      motorcycleSlider ${homeSliderItem}
      powerProductSlider ${homeSliderMarineItem}
      marineSlider ${homeSliderMarineItem}
      stayConnected {
        title
        items {
        position
        url
          iconClass
          image ${image}
        }
      }
      offers {
        title
        subTitle
        offers {
          title 
          image${image}
          description
          link
        }
      }
    }
  }
`;
};
export const getMotorcycleLandingQuery = (language: string, type: string) => {
  return `query MotorcycleLandings {
    motorcycleLandings(locale: "${language}",where:{type:"${type}",${statusConditionWithType}} )  ${listLanding}
  }
`;
};
export const getAutomobileOfferQuery = (language: string) => {
  return `query CarOffers {
    carOffers(locale: "${language}",where:{${statusConditionWithType}} )  ${offer}
  }
`;
};

export const getOwnersQuery = (language: string) => {
  return `query Owners {
    owners(locale: "${language}",${statusCondition} )  ${ownersPage}
  }
`;
};

export const getBodypaintsQuery = (language: string) => {
  return `query Bodypaints {
    bodypaints(locale: "${language}",${statusCondition} )  ${contentPage}
  }
`;
};
export const getFleetesQuery = (language: string) => {
  return `query Fleets {
    fleets(locale: "${language}",${statusCondition} )  ${contentPage}
  }
`;
};
export const getCarServiceQuery = (language: string) => {
  return `query CarServices {
    carServices(locale: "${language}",${statusCondition} )  ${carServicePage}
  }
`;
};
export const getPartsQuery = (language: string) => {
  return `query Parts {
    parts(locale: "${language}",${statusCondition} )  ${contentPage}
  }
`;
};
export const getMotorcycleOfferQuery = (language: string) => {
  return `query MotorcycleOffers {
    motorcycleOffers(locale: "${language}",where:{${statusConditionWithType}} )  ${offer}
  }
`;
};
export const getServiceOfferQuery = (language: string) => {
  return `query ServiceOfferListings {
    serviceOfferListings(locale: "${language}",where:{${statusConditionWithType}} )  ${offer}
  }
`;
};
export const getPowerProductLandingQuery = (language: string, type: string) => {
  return `query PowerProductLandings {
    powerProductLandings(locale: "${language}",where:{type:"${type}",${statusConditionWithType}} ) ${listLanding}
  }
`;
};

export const getMarineLandingQuery = (language: string, type: string) => {
  return `query MaringLandings {
    maringLandings(locale: "${language}",where:{type:"${type}",${statusConditionWithType}} )  ${listLanding}
  }
`;
};

export const getpowerProductDealersQuery = (language: string) => {
  return `query PowerProductDealers {
    powerProductDealers(locale: "${language}",${statusCondition} )  ${powerProductDealerPage}
  }
`;
};

export const getAutoMobileLandingQuery = (language: string) => {
  return `query AutoMobileLandings {
    autoMobileLandings(locale: "${language}",${statusCondition} )  ${autoMobileLandingPage}
  }
`;
};

export const getMotorCylceMenuLandingQuery = (language: string) => {
  return `query MotorCylceMenuLanding {
    motorCylceMenuLandings(locale: "${language}",${statusCondition} )  ${motorCylceMenuLandingPage}
  }
`;
};

export const getPowerProductMenuLandingsQuery = (language: string) => {
  return `query PowerProductMenuLandings {
    powerProductMenuLandings(locale: "${language}",${statusCondition} )  ${powerProductMenuLandingPage}
  }
`;
};

export const getMarineMenuLandingsQuery = (language: string) => {
  return `query MainersMenuLandings {
    mainersMenuLandings(locale: "${language}",${statusCondition} )  ${marineMenuLandingPage}
  }
`;
};
const imageWithDescription = `{
    id,
    title,
    description
    image{
        url
    }
}`;

const localizations = `{
    locale
    id
    slug
  }`;

const button = `{
  color
    text
      relativeUrl
      absoluteUrl
      vehicle {
        id
        title
        slug
      }
  }`;

const midSlider = `{
    title
    description
    image {
      url
    }
    vehicle {
      id
      title
      slug
      price
      offerPrice
    }
    button ${button}
  }`;
const contact = `{
    title
    address
    phone
    email
    link
  }`;
const relation = ` automobile {
    id
    title
    slug
    locale
    localizations ${localizations}
  }
  motorcycle{
    id
    title
    slug
    locale
    localizations ${localizations}
  }
  marine{
    id
    title
    slug
    locale
    localizations ${localizations}
  }
  power_product{
    id
    title
    slug
    locale
    localizations ${localizations}
  }
  `;

export const bannerItem = ` {
    title
    type
    relativeUrl
    absoluteUrl
    description
    image {
      url
      formats
    }
    button {
      color
      text
      relativeUrl
      absoluteUrl
      ${relation}
    }
  }`;

const listLanding = `{
    metaDetails ${metaDetails}
    type
    banner ${bannerItem}
    bottomBanner1 ${bannerItem}
    bottomBanner2 ${bannerItem}
    bottomBanner3 ${bannerItem}
  }`;

const offer = `{
    banner ${bannerItem}
    offers {
      title
      startingAt
      description
      image ${image}
      bannerImage ${image}
      ecomModelId
      button {
        text
        action
      }
      slug
    }
    metaDetails ${metaDetails}
  }`;

const contentPage = `{
    banner ${bannerItem}
    content
    metaDetails ${metaDetails}
  }`;

const ownersPage = `{
      metaDetails ${metaDetails}
      banner ${bannerItem}
    manageYourVehicle {
        title
        items {
        title
        description
        icon ${image}
        relativeUrl
        absoluteUrl
      }
    }
    
  }`;

const carServicePage = `{
    banner ${bannerItem}
    content
    menuPriceFile ${image}
    metaDetails ${metaDetails}
  }`;

const powerProductDealerPage = `{
    title
    dealers {
      title
      address
      mobile
    }
  }`;

const autoMobileLandingCategory = `{
    title
    subTitle
    image {
      url
    }
    link
  }`;

export const autoMobileLandingSliderItem = `{
    title
    slug
    price
    image {
      url
    }
    spec1
    spec2
    type
  }`;
const powerProductMenuLandingSliderItem = `{
    title
    slug
    price
    image {
      url
    }
    category
  }`;
const marineMenuLandingSliderItem = `{
    title
    slug
    price
    image {
      url
    }
    type
  }`;
const autoMobileLandingPage = `{
    metaDetails ${metaDetails}
    banner ${bannerItem}
    categoryList ${autoMobileLandingCategory}
    offer ${autoMobileLandingCategory}
    locateUs ${autoMobileLandingCategory}
    carSlider ${autoMobileLandingSliderItem}
  }`;
const motorCylceMenuLandingPage = `{
    metaDetails ${metaDetails}
    banner ${bannerItem}
    categoryList ${autoMobileLandingCategory}
    slider ${autoMobileLandingSliderItem}
  }`;
const powerProductMenuLandingPage = `{
    metaDetails ${metaDetails}
    banner ${bannerItem}
    categoryList ${autoMobileLandingCategory}
    items ${powerProductMenuLandingSliderItem}
  }`;
const marineMenuLandingPage = `{
    metaDetails ${metaDetails}
    banner ${bannerItem}
    categoryList ${autoMobileLandingCategory}
    items ${marineMenuLandingSliderItem}
  }`;
