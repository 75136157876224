export default class Config {
  static readonly MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoic2hhcmFmdWRoZWVuIiwiYSI6ImNrNHNsMGpjYTA1dDAzZG93a2tnNzV3cnEifQ.hZ0R4NowNN6YzbhOCZFGQg';
  static GOOGLE_ANALYTICS_ID = 'UA-155985479-1';
  static FACEBOOK_APP_ID = '411990470079663';
  static GOOGLE_CLIENT_ID =
    '753882680936-pt5upril6tg3kp9c9d1idlnqgtt7bgrt.apps.googleusercontent.com';
  static WHATSAPP_NO = '9651822777';
  static franchise = 'honda';
}
