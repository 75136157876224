import { post } from 'services/http';

import {
  IAboutUsPageResponse,
  IContactUs,
  ILandingScreenData,
  IMotorcycleLanding,
  INewsItem,
  IOfferPageResponse,
  IProductDetails,
  IRichTextContent,
} from './cms-store';
import {
  getAboutServiceScreenQuery,
  getAboutUSScreenQuery,
  getContactUsScreenQuery,
} from './cmsExtraQuries';
import {
  IAboutServiceResponse,
  IAutoMobileLandingPage,
  IBodyPaintPage,
  ICarServicePage,
  IFleetPage,
  IMarineMenuLandingPage,
  IMotorCylceLandingPage,
  IOfferPage,
  IOwnersPage,
  IPartsPage,
  IPowerProductDealer,
  IPowerProductMenuLandingPage,
  IPowerProducts,
  IVehicleWarrantyPageResponse,
} from './cms-view-models';
import {
  getAllVehiclesQuery,
  getServiceScreenQuery,
  getNewsDetailsQuery,
  getNewsQuery,
  getLandingScreenQuery,
  getOfferScreenQuery,
  getFooterQuery,
  getDisclaimerQuery,
  getTermsQuery,
  getPrivacyQuery,
  getAllMotorcycleQuery,
  getMotorcycleLandingQuery,
  getAllPowerProductQuery,
  getAllMarineQuery,
  getPowerProductLandingQuery,
  getMarineLandingQuery,
  getVehicleDetailsQuery,
  getMarinesForLandingQuery,
  getMarineDetailQuery,
  getMotorcyclesForLandingQuery,
  getMotorcycleDetailQuery,
  getPowerProductsForLandingQuery,
  getPowerProductDetailQuery,
  getMainMenuQuery,
  getAutomobileOfferQuery,
  getMotorcycleOfferQuery,
  getBodypaintsQuery,
  getFleetesQuery,
  getPartsQuery,
  getOwnersQuery,
  getVehicleWarrantyScreenQuery,
  getCarServiceQuery,
  getServiceOfferQuery,
  getpowerProductDealersQuery,
  getAutoMobileLandingQuery,
  getMotorCylceMenuLandingQuery as getMotorCylceMenuLandingQuery,
  getPowerProductMenuLandingsQuery,
  getMarineMenuLandingsQuery,
} from './cmsUtils';

const cmsDomain = process.env.REACT_APP_CMS_DOMAIN;
export async function loadAllVehicles(
  language: string
): Promise<IProductDetails[]> {
  const query = getAllVehiclesQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Automobiles',
    query,
  });
  return (response?.data?.data as any)?.automobiles;
}

export async function loadVehicleDetail(
  language: string,
  slug: string
): Promise<IProductDetails> {
  const query = getVehicleDetailsQuery(language, slug);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Automobiles',
    query,
  });
  return (response?.data?.data as any)?.automobiles?.[0];
}

export async function loadMotorcycleDetail(
  language: string,
  slug: string
): Promise<IProductDetails> {
  const query = getMotorcycleDetailQuery(language, slug);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Motorcycles',
    query,
  });
  return (response?.data?.data as any)?.motorcycles?.[0];
}

export async function loadPowerProductDetail(
  slug: string
): Promise<IPowerProducts> {
  const query = getPowerProductDetailQuery(slug);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'PowerProducts',
    query,
  });
  return (response?.data?.data as any)?.powerProducts?.[0];
}

export async function loadMarineDetail(slug: string): Promise<IPowerProducts> {
  const query = getMarineDetailQuery(slug);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Marines',
    query,
  });
  return (response?.data?.data as any)?.marines?.[0];
}

export async function loadAllMotorCycles(
  language: string
): Promise<IProductDetails[]> {
  const query = getAllMotorcycleQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Motorcycles',
    query,
  });
  return (response?.data?.data as any)?.motorcycles;
}

export async function loadAllPowerProducts(
  currentLanguage: string
): Promise<IProductDetails[]> {
  const query = getAllPowerProductQuery(currentLanguage);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'PowerProducts',
    query,
  });
  return (response?.data?.data as any)?.powerProducts;
}

export async function loadAllMarines(
  currentLanguage: string
): Promise<IProductDetails[]> {
  const query = getAllMarineQuery(currentLanguage);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Marines',
    query,
  });
  return (response?.data?.data as any)?.marines;
}

export async function loadMarinesForLanding(
  currentLanguage: string,
  type?: string
): Promise<IPowerProducts[]> {
  const query = getMarinesForLandingQuery(currentLanguage, type);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Marines',
    query,
  });
  return (response?.data?.data as any)?.marines;
}

export async function loadMotorCyclesForLanding(
  currentLanguage: string,
  type: string
): Promise<IProductDetails[]> {
  const query = getMotorcyclesForLandingQuery(currentLanguage, type);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Motorcycles',
    query,
  });
  return (response?.data?.data as any)?.motorcycles;
}

export async function loadPowerproductForLanding(
  currentLanguage: string,
  type?: string
): Promise<IPowerProducts[]> {
  const query = getPowerProductsForLandingQuery(currentLanguage, type);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'PowerProducts',
    query,
  });
  return (response?.data?.data as any)?.powerProducts;
}

export async function loadServicePage(
  language: string
): Promise<IProductDetails[]> {
  const query = getServiceScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Service',
    query,
  });
  return (response?.data?.data as any)?.service;
}

export async function loadNews(slug: string, locale: string): Promise<any> {
  const query = getNewsDetailsQuery(locale, slug);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'NewsItems',
    query,
  });
  return (response?.data?.data as any)?.newsItems?.[0];
}

export async function loadNewsItems(
  language: string,
  count?: number
): Promise<INewsItem[]> {
  const query = getNewsQuery(language, count);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'NewsItems',
    query,
  });
  return (response?.data?.data as any)?.newsItems;
}

export async function loadLandingPage(
  language: string
): Promise<ILandingScreenData> {
  const query = getLandingScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MainLandingScreens',
    query,
  });
  return (response?.data?.data as any)?.mainLandingScreens?.[0];
}

export async function loadMainMenu(
  language: string
): Promise<ILandingScreenData> {
  const query = getMainMenuQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MainMenus',
    query,
  });
  return (response?.data?.data as any)?.mainMenus?.[0];
}

export async function loadMotorcycleLandingPage(
  language: string,
  type: string
): Promise<IMotorcycleLanding> {
  const query = getMotorcycleLandingQuery(language, type);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MotorcycleLandings',
    query,
  });
  return (response?.data?.data as any)?.motorcycleLandings?.[0];
}

export async function loadAutomobileOfferPage(
  language: string
): Promise<IOfferPage> {
  const query = getAutomobileOfferQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'CarOffers',
    query,
  });
  return (response?.data?.data as any)?.carOffers?.[0];
}

export async function loadOwnersPage(language: string): Promise<IOwnersPage> {
  const query = getOwnersQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Owners',
    query,
  });
  return (response?.data?.data as any)?.owners?.[0];
}
export async function loadBodyPaintPage(
  language: string
): Promise<IBodyPaintPage> {
  const query = getBodypaintsQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Bodypaints',
    query,
  });
  return (response?.data?.data as any)?.bodypaints?.[0];
}

export async function loadFleetsPage(language: string): Promise<IFleetPage> {
  const query = getFleetesQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Fleets',
    query,
  });
  return (response?.data?.data as any)?.fleets?.[0];
}

export async function loadCarServicePage(
  language: string
): Promise<ICarServicePage> {
  const query = getCarServiceQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'CarServices',
    query,
  });
  return (response?.data?.data as any)?.carServices?.[0];
}
export async function loadPowerProductDealerPage(
  language: string
): Promise<IPowerProductDealer[]> {
  const query = getpowerProductDealersQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'PowerProductDealers',
    query,
  });
  return (response?.data?.data as any)?.powerProductDealers;
}
export async function loadAutoMobileLandingPage(
  language: string
): Promise<IAutoMobileLandingPage> {
  const query = getAutoMobileLandingQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'AutoMobileLandings',
    query,
  });
  return (response?.data?.data as any)?.autoMobileLandings?.[0];
}
export async function loadMotorCylceMenuLandingPage(
  language: string
): Promise<IMotorCylceLandingPage> {
  const query = getMotorCylceMenuLandingQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MotorCylceMenuLanding',
    query,
  });
  return (response?.data?.data as any)?.motorCylceMenuLandings?.[0];
}

export async function loadPowerProductMenuLandingPage(
  language: string
): Promise<IPowerProductMenuLandingPage> {
  const query = getPowerProductMenuLandingsQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'PowerProductMenuLandings',
    query,
  });
  return (response?.data?.data as any)?.powerProductMenuLandings?.[0];
}

export async function loadMarineMenuLandingPage(
  language: string
): Promise<IMarineMenuLandingPage> {
  const query = getMarineMenuLandingsQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MainersMenuLandings',
    query,
  });
  return (response?.data?.data as any)?.mainersMenuLandings?.[0];
}

export async function loadVehicleWarrantyPage(
  language: string
): Promise<IVehicleWarrantyPageResponse> {
  const query = getVehicleWarrantyScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'VehicleWarranties',
    query,
  });
  return (response?.data?.data as any)?.vehicleWarranties?.[0];
}

export async function loadPartsPage(language: string): Promise<IPartsPage> {
  const query = getPartsQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Parts',
    query,
  });
  return (response?.data?.data as any)?.parts?.[0];
}
export async function loadMotorcycleOfferPage(
  language: string
): Promise<IOfferPage> {
  const query = getMotorcycleOfferQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MotorcycleOffers',
    query,
  });
  return (response?.data?.data as any)?.motorcycleOffers?.[0];
}
export async function loadServiceOfferPage(
  language: string
): Promise<IOfferPage> {
  const query = getServiceOfferQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'ServiceOfferListings',
    query,
  });
  return (response?.data?.data as any)?.serviceOfferListings?.[0];
}
export async function loadPowerProductLandingPage(
  language: string,
  type: string
): Promise<IMotorcycleLanding> {
  const query = getPowerProductLandingQuery(language, type);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'PowerProductLandings',
    query,
  });
  return (response?.data?.data as any)?.powerProductLandings?.[0];
}

export async function loadMarineLandingPage(
  language: string,
  type: string
): Promise<IMotorcycleLanding> {
  const query = getMarineLandingQuery(language, type);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'MaringLandings',
    query,
  });
  return (response?.data?.data as any)?.maringLandings?.[0];
}

export async function loadContactUsPage(language: string): Promise<IContactUs> {
  const query = getContactUsScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Contactuses',
    query,
  });
  return (response?.data?.data as any)?.contactuses?.[0];
}

export async function loadAboutUsPage(
  language: string
): Promise<IAboutUsPageResponse> {
  const query = getAboutUSScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Aboutuses',
    query,
  });
  return (response?.data?.data as any)?.aboutuses?.[0];
}

export async function loadAboutServicePage(
  language: string
): Promise<IAboutServiceResponse> {
  const query = getAboutServiceScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'AboutServices',
    query,
  });
  return (response?.data?.data as any)?.aboutServices?.[0];
}

export async function loadOfferPage(
  language: string
): Promise<IOfferPageResponse> {
  const query = getOfferScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Offer',
    query,
  });
  return (response?.data?.data as any)?.offer;
}

export async function loadFooterData(
  language: string
): Promise<IOfferPageResponse> {
  const query = getFooterQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Footers',
    query,
  });
  return (response?.data?.data as any)?.footers?.[0];
}
export async function loadDisclaimerData(
  language: string
): Promise<IRichTextContent> {
  const query = getDisclaimerQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Disclaimers',
    query,
  });
  return (response?.data?.data as any)?.disclaimers?.[0];
}

export async function loadTermsData(
  language: string
): Promise<IRichTextContent> {
  const query = getTermsQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Terms',
    query,
  });
  return (response?.data?.data as any)?.terms?.[0];
}

export async function loadPrivacyData(
  language: string
): Promise<IRichTextContent> {
  const query = getPrivacyQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Privacies',
    query,
  });
  return (response?.data?.data as any)?.privacies?.[0];
}
